import {nonErrorlog} from '../../utils/logging'
import {getLandingPageId} from '../../utils/jsTag'

export const getCheckoutAttributes = (shop: string) => {
  // TODO: This is bad practice and we need to fix this by using /v1/merchant properly
  //   However, we are under time-crunch and this is a quick fix
  const isShopify = shop.indexOf('.myshopify.com') > -1

  let attributes: string = ''
  const userId = (window as any).sdxCapture?.userId || null
  const sessionId = (window as any).sdxCapture?.sessionId || null
  const experienceId = (window as any).sdxCapture?.experienceId || null
  const sdxCampaignId = (window as any).sdxCapture?.sdxCampaignId || null
  const landingPageId = getLandingPageId()
  const currentPageId = (window as any).sdxCapture?.currentPageId || null
  const landingPageUrl = (window as any).sdxCapture?.landingPageUrl || null
  const currentPageUrl = (window as any).sdxCapture?.currentPageUrl || null
  const checkoutId = (window as any).sdxCapture?.checkoutId || null
  const utmSource = (window as any).sdxCapture?.utmSource || null
  const utmMedium = (window as any).sdxCapture?.utmMedium || null
  const utmCampaign = (window as any).sdxCapture?.utmCampaign || null

  nonErrorlog(`Attributes log: userId ${userId}; sessionId ${sessionId}; experienceId ${experienceId}; utmSource ${utmSource}; utmMedium ${utmMedium}; utmCampaign ${utmCampaign} `)

  const formatAttribute = (name: string, value: string | null) => {
    return value ? (isShopify ? `&attributes[${name}]=${value}` : `&${name}=${value}`) : ''
  }

  attributes += formatAttribute('userId', userId)
  attributes += formatAttribute('sessionId', sessionId)
  attributes += formatAttribute('experienceId', experienceId)
  attributes += formatAttribute('sdxCampaignId', sdxCampaignId)
  attributes += formatAttribute('landingPageId', landingPageId)
  attributes += formatAttribute('currentPageId', currentPageId)
  attributes += formatAttribute('landingPageUrl', landingPageUrl)
  attributes += formatAttribute('currentPageUrl', currentPageUrl)
  attributes += formatAttribute('checkoutId', checkoutId)
  attributes += formatAttribute('utmSource', utmSource)
  attributes += formatAttribute('utmMedium', utmMedium)
  attributes += formatAttribute('utmCampaign', utmCampaign)

  return attributes
}
