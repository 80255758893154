import {pagePlatformName, pageShopName} from './api/config'

export const getShopUrl = (searchParams: URLSearchParams) => {
  const shop = searchParams.get(pageShopName)
  const platform = searchParams.get(pagePlatformName)

  const shopifyPostfix = '.myshopify.com'
  const salesforcePostfix = ''

  if (platform === 's') return shop + shopifyPostfix
  if (platform === 'f') return shop + salesforcePostfix

  // default
  return shop + shopifyPostfix
}
