import {componentServiceBaseUrl} from './config'
import {logging} from '../utils/logging'
import {ContainerConfig, ImageFit} from './components'

export enum LineItemFieldEnum {
  PRODUCT_NAME = 'PRODUCT_NAME',
  VARIANT_NAME = 'VARIANT_NAME',
  ITEMIZED_OPTIONS = 'ITEMIZED_OPTIONS',
  LOW_INVENTORY = 'LOW_INVENTORY',
}

export enum DisplayTypesEnum {
  MINIFIED = 'MINIFIED',
  EXTENDED = 'EXTENDED',
}

export enum ContainerTypeEnum {
  CAROUSEL = 'CAROUSEL',
  GRID = 'GRID',
}

export enum ImageFitEnum {
  CONTAIN = 'contain',
  COVER = 'cover',
  FILL = 'fill',
}

type DeepRequired<T> = {
  [K in keyof T]-?: DeepRequired<T[K]>;
}

export type DeepRequiredPrimitiveSettings = DeepRequired<PrimitiveSettings>

export type PrimitiveSettings = {
  product: {
    displayType?: DisplayTypesEnum
    containerType?: ContainerConfig['containerType']
    uiSettings: {
      imageFit: ImageFit
      showImageSearch: boolean
    }
  }
  category: {
    displayType?: DisplayTypesEnum
    containerType?: ContainerConfig['containerType']
    uiSettings: {
      imageFit: ImageFit
    }
  }
  storefront: {
    displayType?: DisplayTypesEnum
    containerType?: ContainerConfig['containerType']
    uiSettings: {
      imageFit: ImageFit
    }
  }
}

export const defaultPrimitiveSettings: DeepRequiredPrimitiveSettings = {
  product: {
    containerType: ContainerTypeEnum.CAROUSEL,
    displayType: DisplayTypesEnum.EXTENDED,
    uiSettings: {
      imageFit: ImageFitEnum.CONTAIN,
      showImageSearch: true,
    },
  },
  category: {
    containerType: ContainerTypeEnum.CAROUSEL,
    displayType: DisplayTypesEnum.EXTENDED,
    uiSettings: {
      imageFit: ImageFitEnum.CONTAIN,
    },
  },
  storefront: {
    containerType: ContainerTypeEnum.CAROUSEL,
    displayType: DisplayTypesEnum.EXTENDED,
    uiSettings: {
      imageFit: ImageFitEnum.CONTAIN,
    },
  },
}

export const getPrimitiveSettings = async (shop: string, shopDomain: string) => {
  try {
    const config: RequestInit = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'shop': shop,
        'shop-domain': shopDomain,
      },
      cache: 'default',
    }

    const url = componentServiceBaseUrl + '/v2/primitives/settings'
    const response: PrimitiveSettings = await fetch(url, config)
      .then(async (response) => {
        if (!response.ok) {
          const errBody = await response.json()
          throw new Error(`request failed with status ${response.status}: ${errBody.error}`)
        }
        return response.json()
      })
      .catch(err => {
        throw new Error(`fetch request failed: ${err}`)
      })

    return response

  }
  catch (error) {
    logging(error, {tags: {section: 'getPrimitiveSettings'}})
  }
}