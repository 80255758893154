import {ContainerConfig} from '../api/components'

export const getInitialFooterConfig = (): ContainerConfig => {
  const now = new Date().getTime()
  return ({
    visibleInLibrary: false,
    status: 'PUBLISHED',
    createdAt: now,
    updatedAt: now,
    componentType: 'FOOTER',
    containerType: 'GRID',
    contentType: 'FOOTER',
    internalDescription: 'Footer',
    internalName: 'Footer',
    title: '',
    componentId: 'uninitialized_footer',
    contentConfiguration: {
      type: 'FOOTER',
      value: {
        uiSettings: {
          backgroundColor: '#ffffff',
          textColor: '#ffffff',
        },
        apiSettings: {
          columns: [],
        },
      },
    },
    class: 'HOOK',
  })
}
