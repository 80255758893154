// import * as Sentry from '@sentry/react'
import {CaptureContext} from '@sentry/types'


export const logging = (exception: any, captureContext?: CaptureContext | undefined) => {
  if (process.env.REACT_APP_SENTRY_ENV === 'testing' || process.env.REACT_APP_SENTRY_ENV === 'production') {
    // return Sentry.captureException(exception, captureContext)
  }
}

// Need to swap this out of Sentry to AWS infra
export const nonErrorlog = (exception: any, captureContext?: CaptureContext | undefined) => {
  if (process.env.REACT_APP_SENTRY_ENV === 'testing' || process.env.REACT_APP_SENTRY_ENV === 'production') {
    // return Sentry.captureException(exception, {tags: {section: 'Non Error log from storefront'}})
  }
}
