type FontType = {
  family: string
  url: string
}

export const setFonts = (fonts: FontType[]) => {
  const fontFaces = fonts.map((font) => {
    return new FontFace(font.family, `url(${font.url})`)
  })

  const loadFontFace = (async (fontFace: any) => {
    const loadedFont = await fontFace.load()
    document.fonts.add(loadedFont)
  })

  fontFaces.forEach((fontFace) => {
    loadFontFace(fontFace)
  })
}

export const getFontFamily_sideEffect = (fontNamePrefix: string, fontUrl: string) => {
  const lastPath = getLastFontPath(fontUrl)
  if (!lastPath) return undefined

  const fontFamily = `${fontNamePrefix}_${lastPath}`
  setFonts([{url: fontUrl, family: fontFamily}])

  return fontFamily
}

export const getLastFontPath = (string: string) => {
  if (!string) return ''
  const lastSlashIndex = string.lastIndexOf('/')
  if (lastSlashIndex === -1) {
    console.warn('getLastFontPath: no slash found in string', string)
    return string
  }
  return string.substring(lastSlashIndex + 1).replace(/[^a-zA-Z]+/g, '')
}
