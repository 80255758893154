import {History} from 'history'

// PageIDSocialHome constant for the home page id.
export const PageIDSocialHome = 'home'

export enum SearchParamPageID {
  Campaign = 'id',
  DynamicProduct = 'product',
  DynamicCategory = 'category',
  Component = 'cid',
  Variant = 'vid',
}

// getURLSearchParams returns the URLSearchParams object of the current URL.
export const getURLSearchParams = (): URLSearchParams => {
  return new URLSearchParams(window.location.search)
}

// getURLSearchParamsValue returns the value of the key in the URLSearchParams object and returns the default value if the key does not exist.
const getURLSearchParamsValue = (params: URLSearchParams, key: string, defaultValue: string = ''): string => {
  return params.get(key) || defaultValue
}

// getPageIdFromSearchParams returns the page id from the URLSearchParams object.
export const getPageIdFromSearchParams = (params: URLSearchParams): string => {
  return getURLSearchParamsValue(params, SearchParamPageID.Campaign)
}

// setURLSearchParamPageID clears all conflicting parameters and sets the desired parameter.
export const setURLSearchParamPageID = (params: URLSearchParams, key: SearchParamPageID, value: string): void => {
  Object.values(SearchParamPageID).forEach((param) => {
    if (param !== key) {
      params.delete(param)
    }
  })

  params.set(key, value)
}


// setPageIdToSearchParams sets the page id to the URLSearchParams object and clears all conflicting parameters.
export const setPageIdToSearchParams = (params: URLSearchParams, pageId: string): void => {
  setURLSearchParamPageID(params, SearchParamPageID.Campaign, pageId)
}

export const pushHistory = (history: History, params: URLSearchParams): void => {
  history.push({
    pathname: window.location.pathname,
    search: '?' + params.toString(),
  })
}
