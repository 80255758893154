import {componentServiceBaseUrl, socialServiceBaseUrl} from './config'
import {PageConfig} from '../config'
import {logging} from '../utils/logging'
import {GetStorefrontPrimitivesOptions} from '../components/Storefronts'

type ChannelType = 'instagram' | 'ad'
type PostsRequest = {postId: string, accountId: string, channelType: ChannelType}
type PostsResponse = {postId: string, accountId: string, thumbnail: string}

const attachImageUrlToPageConfigs = async (shop: string, configs: PageConfig[]) => {
  const posts: PostsRequest[] = []

  for (const config of configs) {
    const firstImage = config.campaignMedias[0]
    if (!firstImage) continue

    const channelType = firstImage.postType
    if (channelType !== 'instagram' && channelType !== 'ad') continue

    const postAccountId = firstImage.postAccountId
    const postId = firstImage.postId
    if (!postAccountId || !postId) continue

    if (!posts.find(p => p.accountId === postAccountId && p.postId === postId))
      posts.push({channelType, accountId: postAccountId, postId: postId})
  }

  const postsPromise: Promise<PostsResponse[]> = new Promise((resolve, reject) => {
    const instagramPosts = posts.filter(p => p.channelType === 'instagram')
    if (!instagramPosts.length) resolve([])

    const url = `${socialServiceBaseUrl}/v1/instagram/posts`
    const cfg = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        shop,
      },
      body: JSON.stringify(instagramPosts.map (x => ({
        postId: x.postId,
        accountId: x.accountId,
      }))),
    }
    fetch(url, cfg)
      .then(async (response) => {
        if (!response.ok) {
          const errBody = await response.json()
          throw new Error(`request failed with status ${response.status}: ${errBody.error}`)
        }
        return response.json()
      })
      .catch(err => {
        console.warn(`fetch request failed: ${err}`)
        return []
      })
      .then(r => resolve(r))
  })

  const adsPromise: Promise<PostsResponse[]> = new Promise((resolve, reject) => {
    const instagramPosts = posts.filter(p => p.channelType === 'ad')
    if (!instagramPosts.length) resolve([])

    const url = `${socialServiceBaseUrl}/v2/meta-ads/ads`
    const cfg = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        shop,
      },
      body: JSON.stringify(instagramPosts.map (x => ({
        adId: x.postId,
        accountId: x.accountId,
      }))),
    }
    fetch(url, cfg)
      .then(async (response) => {
        if (!response.ok) {
          const errBody = await response.json()
          throw new Error(`request failed with status ${response.status}: ${errBody.error}`)
        }
        return response.json()
      })
      .catch(err => {
        console.warn(`fetch request failed: ${err}`)
        return []
      })
      .then(r => resolve(r))
  })

  const promises = [postsPromise, adsPromise]

  const responses = (await Promise.all(promises))
    .filter(x => x)
    .flat()
    .map (x => {
      // NOTE: this is because we hack the postId with postType === 'id'
      x.postId = x.postId || (x as any).adId
      return x
    })

  configs.forEach(config => {
    const firstImage = config.campaignMedias[0]
    if (!firstImage) return

    const postAccountId = firstImage.postAccountId
    const postId = firstImage.postId
    if (!postAccountId || !postId) return

    if (firstImage.mediumUrl) return
    const post = responses.find(x => /*x.accountId === postAccountId &&*/ x.postId === postId)
    firstImage.mediumUrl = post?.thumbnail || ''
  })

  return configs
}

export const getPageConfigs = async (shop: string, excludedPages?: string, defaultPagination?: GetStorefrontPrimitivesOptions) => {
  try {
    let pageConfigs: PageConfig[] = []
    // first, get new configs from the component service.
    let componentUrl = `${componentServiceBaseUrl}/v1/pages`
    componentUrl += '?status=PUBLISHED'
    if (excludedPages) componentUrl += `&excludedPages=${excludedPages}`
    if (defaultPagination?.limit) componentUrl += `&limit=${defaultPagination.limit}`
    if (defaultPagination?.offset) componentUrl += `&offset=${defaultPagination.offset}`
    let headers = new Headers()
    headers.append('Shop', shop)
    const requestOptions: RequestInit = {
      headers: headers,
    }
    pageConfigs = await fetch(componentUrl, requestOptions)
      .then(async (response) => {
        if (!response.ok) {
          const errBody = await response.json()
          throw new Error(`request failed with status ${response.status}: ${errBody.error}`)
        }
        return response.json()
      })
      .catch(err => {
        throw new Error(`fetch request failed: ${err}`)
      })

    pageConfigs = await attachImageUrlToPageConfigs(shop, pageConfigs)
    return pageConfigs
  }
  catch (error) {
    logging(error, {tags: {section: 'getPageConfigs'}})
  }
}