import React from 'react'
import type {FC} from 'react'
import {Container, Grid} from '@mui/material'


const ErrorBoundry: FC = () => {

  return (
    <div>
      <Container fixed>
        <Grid
          container
          justifyContent={'center'}
          alignItems='center'
          direction='column'
          spacing={2}
        >
          <Grid item>
            <span>
              <div />
              Something went wrong
              <div />
            </span>
          </Grid>
          <Grid item>
            <span>
              Sorry for that, this error has been automatically reported.
            </span>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default ErrorBoundry
