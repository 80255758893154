declare module '@mui/material/styles' {
  interface PaletteOptions {
    custom?: {
      grey_background?: React.CSSProperties['color']
    }
  }
  interface ThemeOptions {
    custom?: {
      grey_background?: React.CSSProperties['color']
    }
  }
}

export const breakPoints = {
  xs: {min: 0, max: 559},
  sm: {min: 560, max: 767},
  md: {min: 768, max: 1007},
  lg: {min: 1008, max: 1775},
  xl: {min: 1776},
}

export const getDefaultTheme = () => ({
  breakpoints: {
    values: {
      xs: breakPoints.xs.min,
      sm: breakPoints.sm.min,
      md: breakPoints.md.min,
      lg: breakPoints.lg.min,
      xl: breakPoints.xl.min,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {
          backgroundColor: '#FFFFFF',
        },
      },
    },
  },
  palette: {
    primary: {
      // NOTE: this makes the theme neutral until colors are fetched
      main: '#AAA',
    },
    custom: {
      grey_background: '#F2F4F7',
    },
  },
})
