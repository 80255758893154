import {ReactElement} from 'react'
import {MultiSourceImage, ProductImage} from '../config'
import {getElementFromHtmlString} from '../getElementFromHtmlString'
import {productServiceUrl} from './config'
import {logging} from '../utils/logging'
import {getMultiSourceImageWithDefaults} from '../config/context'
import {PrimitiveType} from './components'

export type NormalizedCategoryPrimitive = MultiSourceImage & {
  id: string
  title: string
  description: ReactElement
  url: string
  type: PrimitiveType
}

// const normalizeCategory = (rawCategory: RawCategory) => {
//   return {
//     id: rawCategory.id,
//     title: rawCategory.title,
//     description: getElementFromHtmlString(rawCategory.description || ''),
//     image: rawCategory.featured_image?.url,
//     url: rawCategory.cdp_url,
//   }
// }

export type CategoryPrimitive = {
  id: string
  externalId: string
  title: string
  description: string
  cdpUrl: string
  featuredImage?: ProductImage
}

export type GetCategoryPrimitivesOptions = {
  categoryIds?: string[]
  limit?: number
  offset?: number
}

export const getCategoryPrimitives = async (shop: string, options: GetCategoryPrimitivesOptions) => {
  try {
    const {categoryIds, limit, offset} = options

    const config : RequestInit = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        shop: shop,
      },
    }

    const query = {} as any
    if (categoryIds) query['categoryIds'] = categoryIds.join(',')
    if (limit) query['limit'] = limit
    if (offset) query['offset'] = offset

    const queryString = new URLSearchParams(query).toString()
    const url = `${productServiceUrl}/v1/categories/primitives/?${queryString}`

    // .then handles a non-200 response from server, .catch handles the fetch api failing.
    const response: CategoryPrimitive[] = await fetch(url, config)
      .then(async (response) => {
        if (!response.ok) {
          const errBody = await response.json()
          throw new Error(`request failed with status ${response.status}: ${errBody.error}`)
        }
        return response.json()
      })
      .catch(err => {
        throw new Error(`fetch request failed: ${err}`)
      })

    return response.map(normalizeCategoryPrimitive)
  }
  catch (error) {
    logging(error, {tags: {section: 'getCategoryPrimitives'}})
  }
}

const normalizeCategoryPrimitive = (categoryPrimitive: CategoryPrimitive): NormalizedCategoryPrimitive => {
  return {
    id: categoryPrimitive.id,
    title: categoryPrimitive.title,
    description: getElementFromHtmlString(categoryPrimitive.description),
    ...getMultiSourceImageWithDefaults(categoryPrimitive.featuredImage),
    url: categoryPrimitive.cdpUrl,
    type: 'CATEGORY_PRIMITIVE',
  }
}