import {CartContent, ContainerConfig} from '../api/components'
import {LineItemFieldEnum} from '../api/primitiveSettings'

export const getInitialCartConfig = (): ContainerConfig => {
  const now = new Date().getTime()
  const cartContent: CartContent = {
    type: 'LINE_ITEM',
    value: {
      uiSettings: {
        fields: [
          LineItemFieldEnum.PRODUCT_NAME,
          LineItemFieldEnum.ITEMIZED_OPTIONS,
        ],
        icon: {
          url: '',
          badgingColor: '#000000',
          badgingHoverColor: '#000000',
          textColor: '#000000',
          textHoverColor: '#000000',
          backgroundColor: '#ffffff',
          backgroundHoverColor: '#ffffff',
        },
        header: {
          text: 'Your shopping cart',
          html: '',
        },
        footer: {
          text: 'Shipping, taxes and discount codes calculated at checkout.',
          html: '',
        },
        checkoutButton: {
          text: 'Continue to checkout',
          backgroundColor: '#ffffff',
          backgroundHoverColor: '#ffffff',
          textColor: '#000000',
          textHoverColor: '#000000',
          outlineColor: '#000000',
          outlineHoverColor: '#000000',
          outlineWidthPixels: 2,
          fontUrl: '',
        },
      },
      apiSettings: {
      },
    },
  }
  return ({
    visibleInLibrary: false,
    status: 'PUBLISHED',
    createdAt: now,
    updatedAt: now,
    componentType: 'CART',
    containerType: 'GRID',
    contentType: 'CART',
    internalDescription: 'Cart',
    internalName: 'Cart',
    title: '',
    componentId: 'uninitialized_cart',
    contentConfiguration: cartContent,
    class: 'HOOK',
  })
}
